.DashboardSection {
  &__image-container {
    max-width: 370px;
  }
}

.react-kanban-column > div:nth-of-type(2) {
  //max-height: calc(100vh - 250px) !important; //El original
  //max-height: 100vh !important; //Cambio de Hugo
  overflow-y: scroll;
}
